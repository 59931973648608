const ImageHandler = ({ images }) => {
  let imageUrls = [];

  // Case 1: If images is a string (semicolon-separated URLs)
  if (typeof images === "string") {
    imageUrls = images.split("; ").map((url) => url.trim());
  }

  // Case 2: If images is an array of objects
  else if (Array.isArray(images)) {
    imageUrls = images.map((image) => image.url);
  }

  // If no images found, return a default whale emoji
  return imageUrls.length > 0 ? imageUrls : ["🐋"];
};

export default ImageHandler;
