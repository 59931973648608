import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faSwimmer,
  faShip,
  faLeaf,
  faHandsHelping,
  faBan,
  faShieldAlt,
  faRecycle,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import "./WhyBookWithUs.css";

const WhyBookWithUs = () => {
  return (
    <section className='why-book-section'>
      <div className='why-book-container'>
        <h2>Why Book With Us?</h2>
        <p>
          At Awesome Whale Adventures, we specialise in providing unforgettable
          whale watching and whale swimming tours & adventures.
        </p>

        <p>
          <strong>Here’s why you should choose us:</strong>
        </p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faUsers} /> Expert-led whale watching tours
            with experienced guides.
          </li>
          <li>
            <FontAwesomeIcon icon={faSwimmer} /> Opportunities for swimming with
            whales, a once-in-a-lifetime experience.
          </li>
          <li>
            <FontAwesomeIcon icon={faShip} /> Multi-day whale watching trips to
            explore the beauty of marine life over several days.
          </li>

          <li>
            <FontAwesomeIcon icon={faLeaf} /> Ethical and eco-conscious
            practices that prioritise whale safety and conservation.
          </li>
          <li>
            <FontAwesomeIcon icon={faHandsHelping} /> Support for local
            communities and sustainable tourism initiatives.
          </li>
        </ul>
      </div>

      {/* Ethics Section */}
      <div className='ethics-section'>
        <h2>Our Commitment to Ethical Whale Tourism</h2>
        <p>
          We are dedicated to providing ethical whale swimming and whale
          watching tours that respect the natural behavior of these majestic
          creatures.
        </p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faBan} /> <strong>No Pursuit:</strong> We
            respect the whales' natural movements and never chase them.
          </li>
          <li>
            <FontAwesomeIcon icon={faShieldAlt} />{" "}
            <strong>Safe Distance:</strong> We maintain a respectful distance
            for both the whales' and your safety.
          </li>
          <li>
            <FontAwesomeIcon icon={faRecycle} />{" "}
            <strong>Environmental Sustainability:</strong> Every tour is
            designed with conservation in mind.
          </li>
          <li>
            <FontAwesomeIcon icon={faHandshake} />{" "}
            <strong>Responsible Partnerships:</strong> We only work with
            partners who share our values for eco-friendly tours.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default WhyBookWithUs;
