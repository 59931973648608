import React from "react";
import { Hero, SEO, WhyBookWithUs } from "../components";

const Home = () => {
  return (
    <>
      <SEO
        title='Home'
        description='Explore unforgettable whale expeditions with Awesome Whale Adventures. Ethical, eco-friendly experiences for ocean lovers, from swimming to watching.'
        keywords='whale expeditions, ethical whale tours, whale swimming, whale watching'
      />
      <Hero />
      <WhyBookWithUs />
    </>
  );
};

export default Home;
