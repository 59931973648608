import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { Loading } from "../index";
import "./Blog.css";

const BlogIndex = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogCollection = collection(db, "blogs");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        slug: doc.data().slug,
        timeStamp: doc.data().timeStamp,
        ...doc.data(),
      }));

      // Sort blogs by date (most recent first)
      const sortedBlogs = blogList.sort((a, b) => {
        const dateA = a.timeStamp?.seconds * 1000;
        const dateB = b.timeStamp?.seconds * 1000;
        return dateB - dateA; // Sort by most recent
      });

      setBlogs(sortedBlogs);
      setLoading(false);
    };

    fetchBlogs();
  }, []);

  // Get an excerpt from the content
  const getExcerpt = (content) => {
    if (!content) return "";
    const excerpt = content.replace(/(<([^>]+)>)/gi, "").slice(0, 150);
    return `${excerpt}...`;
  };

  return (
    <div className='blog-preview-container'>
      <h1>Whale Adventures & Stories</h1>
      {loading ? (
        <Loading message='Loading blog posts, please wait...' />
      ) : (
        <div className='blog-posts'>
          {blogs.map((post) => (
            <div key={post.id} className='blog-preview'>
              {post.heroImage && (
                <div className='blog-index-image'>
                  <div className='preview-hero-container'>
                    <img
                      src={post.heroImage}
                      alt={post.title || ""}
                      className='preview-blog-image'
                    />
                  </div>
                </div>
              )}
              <div className='blog-index-text'>
                <h2>{post.title || ""}</h2>
                <p className='blog-excerpt'>
                  {post.excerpt || getExcerpt(post.content)}
                </p>
                <Link to={`/blog/${post.slug}`} className='read-more'>
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogIndex;
