import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords }) => {
  const defaultTitle = "Awesome Whale Adventures";
  const defaultDescription =
    "Discover unforgettable whale swimming and watching tours with Awesome Whale Adventures.";
  const defaultKeywords =
    "whale tours, whale swimming, ethical tourism, whale watching";

  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name='description' content={description || defaultDescription} />
      <meta name='keywords' content={keywords || defaultKeywords} />
    </Helmet>
  );
};

export default SEO;
