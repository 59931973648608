import React from "react";
import "./Sort.css";
import { useResponsive } from "../../hooks";

const Sort = ({ options, onChange, defaultOption }) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = JSON.parse(selectedValue);
    onChange(selectedOption);
  };

  const { isMobile } = useResponsive();

  return (
    !isMobile && (
      <div className='sort-options'>
        <label htmlFor='sort-select'>Sort by:</label>
        <select
          id='sort-select'
          onChange={handleSelectChange}
          value={JSON.stringify(defaultOption)} // Use value instead of defaultValue
        >
          {options.map((option, index) => (
            <option
              key={`${option.value}-${option.order}`}
              value={JSON.stringify(option)}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    )
  );
};

export default Sort;
