import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { Loading } from "../index";
import "./Policy.css";

const PrivacyPolicy = () => {
  const [policyContent, setPolicyContent] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, "siteContent", "privacyPolicy");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Check if the content field exists
          if (data && data.content) {
            setPolicyContent(data.content);
          } else {
            setPolicyContent("<p>No content available for this policy.</p>");
          }
        } else {
          setPolicyContent("<p>No privacy policy found.</p>");
        }
      } catch (error) {
        setError("Error fetching privacy policy: " + error.message);
        console.error("Error fetching privacy policy:", error);
      } finally {
        setIsLoading(false); // Set loading to false when fetching is complete
      }
    };

    fetchContent();
  }, []);

  if (isLoading) {
    return <Loading message='Loading privacy policy, please wait...' />;
  }

  if (error) {
    return <div>{error}</div>; // Display error message if fetching failed
  }

  return (
    <div
      className='policy-container'
      dangerouslySetInnerHTML={{ __html: policyContent }}
    ></div>
  );
};

export default PrivacyPolicy;
