import React, { useState } from "react";
import { useResponsive } from "../../hooks";
import "./DownloadGuide.css";
import "../Hero/Hero.css";
import ContactForm from "../Forms/ContactForm";

const DownloadGuide = () => {
  const [isSent, setIsSent] = useState(false);

  const { isMobile } = useResponsive();
  return (
    <div id='download-guide' className='download-guide-container'>
      {!isSent ? (
        <div className='download-guide'>
          <h1>Claim Your Free Whale Adventure Travel Guide</h1>
          <h3>
            {isMobile
              ? `Uncover top destinations, expert tips, and everything you need to know for your next adventure!`
              : `
              Whether you’re dreaming of swimming with majestic whales or observing from the comfort of a boat, this guide is your ultimate resource for the best destinations, expert travel tips, and everything you need to know to make your adventure unforgettable.`}
          </h3>

          <ContactForm isSent={isSent} setIsSent={setIsSent} />
        </div>
      ) : (
        <div className='download-guide'>
          <h1>Thank you for downloading our Whale Adventure Travel Guide!</h1>

          <h3>
            Inside the guide, you'll find everything you need to start planning
            your unforgettable adventure — whether you're swimming with
            humpbacks in the South Pacific or whale watching in breathtaking
            fjords.
            <br />
            <br />
            Keep an eye on your inbox for more tips, exclusive offers, and
            updates from Awesome Whale Adventures. <br />
            <br />
            If you have any questions or need help planning your trip, feel free
            to reach out to us!
          </h3>
        </div>
      )}
    </div>
  );
};

export default DownloadGuide;
