import React from "react";
import { EnquiryForm, SEO } from "../components";

const EnquiryPage = () => {
  return (
    <>
      <SEO
        title='Enquiry Form'
        description='Enquire about our Awesome Whale Adventures and join us for an unforgettable experience.'
        keywords='enquiry, booking, whale tours'
      />

      <EnquiryForm />
    </>
  );
};

export default EnquiryPage;
