import { useResponsive } from "../../hooks";

const Tabs = ({ tour, selectedTab, setSelectedTab }) => {
  const { isMobile } = useResponsive();
  return (
    <div className='tabs'>
      {selectedTab !== null && (
        <button className='tab-button' onClick={() => setSelectedTab(null)}>
          Back to Overview
        </button>
      )}

      {/* Only render the tab buttons if they are not the selected one */}
      {selectedTab !== "ethics" && (
        <button
          className={`tab-button ${selectedTab === "ethics" ? "active" : ""}`}
          onClick={() => setSelectedTab("ethics")}
        >
          Ethics
        </button>
      )}

      {tour.experience && selectedTab !== "experience" && (
        <button
          className={`tab-button ${
            selectedTab === "experience" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("experience")}
        >
          Experience
        </button>
      )}

      {tour.accommodation && selectedTab !== "accommodation" && (
        <button
          className={`tab-button ${
            selectedTab === "accommodation" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("accommodation")}
        >
          Accommodation
        </button>
      )}

      {tour.location && selectedTab !== "location" && (
        <button
          className={`tab-button ${selectedTab === "location" ? "active" : ""}`}
          onClick={() => setSelectedTab("location")}
        >
          Location
        </button>
      )}
    </div>
  );
};

export default Tabs;
