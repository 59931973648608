import React, { useState, useEffect } from "react";
import { SEO } from "../components";
import { useLocation } from "react-router-dom";

const ThankYouEnquiryPage = () => {
  const { state } = useLocation();
  // Set default tour info if state is available
  const [tourInfo, setTourInfo] = useState(state?.tour || {});

  useEffect(() => {
    if (state && state.tour) {
      setTourInfo(state.tour); // Ensure tourInfo is the tour object
    }
  }, [state]);

  console.log("thank you tour", tourInfo);
  return (
    <div className='pages-container'>
      <SEO
        title='Thank You Page'
        description='Thank you for enquiring about our Awesome Whale Adventures.'
        keywords='thank you, enquiry, booking, whale tours'
      />
      <div className='download-guide'>
        <h1>Thank you for your enquiry!</h1>
        <h3>
          We appreciate your interest in joining our {tourInfo.whaleType} whale
          tour in {tourInfo.country}.
          <br />
          <br />
          Our team will reach out to you within the next 24-48 hours with more
          details.
        </h3>
      </div>
    </div>
  );
};

export default ThankYouEnquiryPage;
