import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "./styles/GlobalStyle";
import {
  NavBar,
  Footer,
  DownloadGuide,
  AdventureTypes,
  BlogPost,
  ScrollToTop,
  ContactUs,
  PrivacyPolicy,
  Terms,
  NotFound,
} from "./components";
import {
  Home,
  Tours,
  AllTours,
  EnquiryPage,
  ThankYouEnquiryPage,
  Blog,
} from "../src/pages";
import ReactPixel from "react-facebook-pixel";
import OldPageRedirect from "./components/Redirect/Redirect";

function App() {
  const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
  const advancedMatching = {};
  const options = { autoConfig: true, debug: false };

  useEffect(() => {
    if (pixelId) {
      ReactPixel.init(pixelId, advancedMatching, options);
      ReactPixel.pageView();
    }
  }, [pixelId]);

  useEffect(() => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (GA_MEASUREMENT_ID) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", GA_MEASUREMENT_ID);
    }
  }, []);

  const footerRef = useRef(null);

  return (
    <Router>
      <>
        <GlobalStyle />
        <NavBar />
        <ScrollToTop />
        <Routes>
          {/* Redirects */}
          <Route
            path='/tonga-whale-swim-adventure'
            element={
              <OldPageRedirect target='/tours/1/tonga/humpback/swim-adventure' />
            }
          />
          <Route
            path='/book-tonga-whale-swim-adventure'
            element={<OldPageRedirect target='/booking-enquiry' />}
          />

          <Route
            path='/'
            element={
              <div className='scroll-container'>
                <Home />
              </div>
            }
          />
          <Route path='/adventure-styles' element={<AdventureTypes />} />
          <Route path='/download-guide' element={<DownloadGuide />} />
          <Route path='/all-tours' element={<AllTours />} />
          <Route path='/swim-tours' element={<AllTours />} />
          <Route path='/see-tours' element={<AllTours />} />
          <Route
            path='/tours/:id/:country/:whaleType/:adventureType'
            element={<Tours />}
          />
          <Route path='/booking-enquiry' element={<EnquiryPage />} />
          <Route path='/thank-you' element={<ThankYouEnquiryPage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:slug' element={<BlogPost />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<Terms />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer ref={footerRef} />
      </>
    </Router>
  );
}

export default App;
