import { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GlobalStyle = createGlobalStyle`

:root {
  min-height: 100vh;
  /* Brand Colors */
  --primary-brand-color: #005a7d; /* Main blue */
  --secondary-brand-color: #008bbf; /* Lighter variant */
  --supplementary-brand-color:#66b2b2;/* Teal color */


  /* Tranparent Variations */
  --secondary-brand-color-transparent: rgba(0, 119, 179, 0.8); /* 80% opacity */
  --supplementary-brand-color-transparent: rgba(76, 163, 163, 0.8); /* 80% opacity */

  /* Neutral Colors */
  --site-black: #02131e; /* Black used throughout the site */
  --site-white: #fafafa;; /* White used throughout the site */
  --site-grey: #333333; /* Gray used throughout the site */

  /* Supporting Colors */
  --accent-color: #00b140;
  --alternate-accent: #ff5733;
  --success-color: #28a745; /* Color for success messages */
  --warning-color: #ffc107; /* Color for warnings */
  --error-color: #dc3545; /* Color for error messages */
  
  /* Text Colors */
  --text-primary: var(--site-grey); /* Main text color */
  --text-secondary: var(--site-white); /* Secondary text color */

  /* Background Colors */
  --background-primary: var(--site-white);
  --background-secondary: var(--site-black);
}

  

    body {
    background-color: var(--primary-brand-color); 
    font-family: 'Poppins', sans-serif; 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth; /* Enables smooth scrolling */
    height: 100%; /* Full height for body and html */
  }

  .scroll-container {
    max-width: 100%; /* Prevents the container from exceeding the viewport */
    overflow: hidden; /* Hides any overflow */
  }

  /* General heading styles */
  h1, h2 {
    width: 100%; /* Full width by default */
    margin: 0; /* Reset margins */
    padding: 0; /* Reset padding */
  }

  h1 {
    color:var(--site-white);
  }
  
  

  /* Header styles */
  .Header {
    background-color: var(--primary-brand-color);
    height: 100vh; /* Full height of viewport */
    display: flex;
    flex-direction: column;
    flex: 1 0 auto; /* Prevents flex items from growing too large */
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin); /* Responsive font size */
    color: var(--site-white)
  }

  /* Add mobile-first styles */
  @media (min-width: 600px) {
    /* Responsive font size adjustments */
    body {
      font-size: 16px; /* Base font size */
    }

    h1 {
      font-size: 4rem; /* Larger font size for h1 on larger screens */
      margin: 0; /* Reset margin */
    }

    h2 {
      font-size: 2rem; /* Larger font size for h2 on larger screens */
      margin: 0; /* Reset margin */
    }
  }
`;

export default GlobalStyle;
