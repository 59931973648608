import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { dbRealtime } from "../firebase/firebaseConfig";
import { ref, get } from "firebase/database";
import { TourDetails, Loading } from "../components";
import { ourTour } from "../data/TourBackUpData";

const Tours = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTourData = async () => {
      try {
        setLoading(true);
        const toursRef = ref(dbRealtime, "tours");
        const snapshot = await get(toursRef);

        if (snapshot.exists()) {
          const toursData = snapshot.val();
          const tourList = Object.entries(toursData).map(([key, tour]) => ({
            id: key,
            ...tour,
          }));
          const foundTour = tourList.find((tour) => tour.id.toString() === id);

          if (foundTour) {
            setTour(foundTour);
          } else {
            throw new Error("Tour not found in database.");
          }
        } else {
          throw new Error("No tours available in database.");
        }
      } catch (error) {
        console.error("Error fetching tour data:", error);

        // Use static data as backup
        const fallbackTour = ourTour.find((tour) => tour.id.toString() === id);
        if (fallbackTour) {
          setTour(fallbackTour);
        } else {
          setError("Tour not found in both database and backup data.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTourData();
  }, [id]);

  if (loading)
    return <Loading message='Loading tour details, please wait...' />;
  if (error) return <p>{error}</p>;
  if (!tour) return <p>Tour not found. Please check the tour ID.</p>;

  return (
    <div className='tour-details-container'>
      <TourDetails tour={tour} />
    </div>
  );
};

export default Tours;
