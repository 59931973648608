import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { Loading } from "../index";
import "./Policy.css";

const Terms = () => {
  const [termsContent, setTermsContent] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, "siteContent", "termsAndConditions");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Check if the content field exists
          if (data && data.content) {
            setTermsContent(data.content);
          } else {
            setTermsContent(
              "<p>No content available for Terms and Conditions.</p>"
            );
          }
        } else {
          setTermsContent("<p>Terms and Conditions not found.</p>");
        }
      } catch (error) {
        setError("Error fetching Terms and Conditions: " + error.message);
        console.error("Error fetching Terms and Conditions:", error);
      } finally {
        setIsLoading(false); // Set loading to false when fetching is complete
      }
    };

    fetchContent();
  }, []);

  if (isLoading) {
    return <Loading message='Loading terms and conditions, please wait...' />;
  }

  if (error) {
    return <div>{error}</div>; // Display error message if fetching failed
  }

  return (
    <div
      className='policy-container'
      dangerouslySetInnerHTML={{ __html: termsContent }}
    ></div>
  );
};

export default Terms;
