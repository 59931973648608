import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { SEO } from "../index";
import { Loading } from "../index";
import "./Blog.css";

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [allPosts, setAllPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const blogCollection = collection(db, "blogs");
        const blogSnapshot = await getDocs(blogCollection);
        const postsData = blogSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAllPosts(postsData);

        const currentPost = postsData.find((post) => post.slug === slug);
        if (currentPost) {
          setPost(currentPost);
        } else {
          setError("Blog post not found, please check URL and try again.");
        }
      } catch (err) {
        console.error("Error fetching blog posts:", err);
        setError("An error occurred while fetching the blog posts.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [slug]);

  if (isLoading) return <Loading message='Loading blog post, please wait...' />;
  if (error) return <p className='error-message'>{error}</p>;

  if (!post) return <p>No blog post available for this URL.</p>;

  const pageTitle = post.title || "Blog Post";
  const pageDescription =
    post.description || "A detailed blog post on whale adventures.";
  const pageKeywords = post.tags?.join(", ") || "whale, travel, adventure";

  const currentIndex = allPosts.findIndex((item) => item.slug === slug);
  const prevPost = allPosts[currentIndex - 1];
  const nextPost = allPosts[currentIndex + 1];

  const goToPost = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <div className='container'>
      <SEO
        title={pageTitle}
        description={pageDescription}
        keywords={pageKeywords}
      />

      <h1>{post.title}</h1>

      {post.heroImage && (
        <div className='hero-container'>
          <img src={post.heroImage} alt={post.title} className='blog-image' />
        </div>
      )}

      <p className='date'>
        <small>
          By {post.author || "Unknown"} on {post.date || "N/A"}
        </small>
      </p>

      <div
        className='blog-content'
        dangerouslySetInnerHTML={{
          __html: post.content || "<p>No content available</p>",
        }}
      />

      {post.tags && (
        <p>
          <strong>Tags:</strong> {post.tags.join(", ")}
        </p>
      )}

      <div className='post-navigation'>
        {prevPost && (
          <button
            onClick={() => goToPost(prevPost.slug)}
            className='nav-arrow'
            aria-label='Go to previous post'
          >
            ← Previous Post
          </button>
        )}
        {nextPost && (
          <button
            onClick={() => goToPost(nextPost.slug)}
            className='nav-arrow'
            aria-label='Go to next post'
          >
            Next Post →
          </button>
        )}
      </div>
    </div>
  );
};

export default BlogPost;
