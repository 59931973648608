import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHotel,
  faWifi,
  faShip,
  faPlane,
  faPersonSwimming,
  faBinoculars,
  faFlask,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import "./Icons.css";

const iconData = {
  swim: { icon: faPersonSwimming, tooltip: "Swimming with Whales" },
  watch: { icon: faBinoculars, tooltip: "Whale Watching" },
  hotel: { icon: faHotel, tooltip: "Hotel or Eco-Resort" },
  altAccom: { icon: faHome, tooltip: "Shared Accommodation" },
  liveAboard: { icon: faShip, tooltip: "Live Aboard" },
  internalFlights: { icon: faPlane, tooltip: "Internal Flights Included" },
  research: { icon: faFlask, tooltip: "Research Tour" },
  wifi: { icon: faWifi, tooltip: "WiFi Available" },
};

const Icon = ({ iconType, show = true }) => {
  if (!show || !iconData[iconType]) return null;

  const { icon, tooltip } = iconData[iconType];

  return (
    <div className='icon-wrapper'>
      <FontAwesomeIcon icon={icon} className='icon' />
      <span className='tooltip'>{tooltip}</span>
    </div>
  );
};

export default Icon;
