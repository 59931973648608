import { seeIcon, swimIcon } from "../../assets";

const TourHeader = ({ tour }) => (
  <div className='tour-header'>
    <div className='section-header'>
      <img
        src={tour.swim ? swimIcon : seeIcon}
        className={`symbols ${tour.swim ? "swim" : "see"}`}
        alt='tour style icon'
      />
      <div className='price-display'>
        <p className='nights'>
          {tour.noOfNights || "N/A"} nights {tour.from ? "from " : ""}
        </p>
        <p className='current-price'>
          ${tour.price?.toLocaleString() || "0"} AUD
        </p>
        <p className='tour-price-details'>pp | twin share</p>
      </div>
    </div>
    <div className='tour-destination'>
      <h2 className='tour-country'>{tour.country || ""}</h2>
      <p className='tour-location'>{tour.location || ""}</p>
    </div>
  </div>
);
export default TourHeader;
