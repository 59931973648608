import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Sort } from "../index";
import { useResponsive } from "../../hooks";
import "./TourFilter.css";

const TourFilter = ({
  whaleType,
  setWhaleType,
  adventureType,
  setAdventureType,
  whaleTypes,
  sortOptions,
  sortOption,
  setSortOption,
}) => {
  const navigate = useNavigate();

  // Handle adventure type change and update the URL
  const handleAdventureTypeChange = (e) => {
    const selectedAdventureType = e.target.value;
    setAdventureType(selectedAdventureType);

    // Update the URL based on the selected adventure type
    if (selectedAdventureType === "Swim") {
      navigate("/swim-tours");
    } else if (selectedAdventureType === "See") {
      navigate("/see-tours");
    } else {
      navigate("/all-tours");
    }
  };

  // Handle whale type change and reset adventure type to 'All'
  const handleWhaleTypeChange = (e) => {
    const selectedWhaleType = e.target.value;
    setWhaleType(selectedWhaleType);
    setAdventureType(""); // Reset adventure type to 'All'
    navigate("/all-tours"); // Navigate to all tours after resetting
  };

  // Reset sort option when whaleType or adventureType changes
  useEffect(() => {
    setSortOption({ value: "", order: "" }); // Reset sort option to default value
  }, [whaleType, adventureType, setSortOption]);

  const { isMobile } = useResponsive();

  return (
    <div className='filters'>
      <div className='top-filters'>
        <label>
          {isMobile ? `Whale:` : `Whale Type:`}
          <select value={whaleType} onChange={handleWhaleTypeChange}>
            <option value=''>All</option>
            {whaleTypes.map((type) => (
              <option key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </option>
            ))}
          </select>
        </label>

        <label>
          {isMobile ? `Style:` : `Adventure Type:`}
          <select value={adventureType} onChange={handleAdventureTypeChange}>
            <option value=''>All</option>
            <option value='Swim'>Swim</option>
            <option value='See'>See</option>
          </select>
        </label>
      </div>

      <div className='sort'>
        <Sort
          options={sortOptions}
          onChange={(value) => setSortOption(value)}
          defaultOption={sortOption}
        />
      </div>
    </div>
  );
};

export default TourFilter;
